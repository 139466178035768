import { Link } from "gatsby";
import dayjs from "dayjs";

import clsx from "clsx";
import Tag from "@/components/elements/Tag";
import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";
import Image from "@/components/elements/Image";

import truncate from "@/base/helpers/truncateString";
import LinkComponent from "../LinkComponent";
import * as styles from "./styles.module.scss";
import { InlineVideo } from "./inline-player";

function HeaderWrapper({
  url,
  children,
}: {
  url: string;
  children: React.ReactNode;
}) {
  if (!url) {
    return <div>{children}</div>;
  }

  return (
    <Link to={url} className={styles.link}>
      {children}
    </Link>
  );
}

export function VerticalCard({
  heroImage,
  publishedDate,
  title,
  excerpt,
  categories,
  url = "/",
  labels,
  className,
  badge,
  getNameByValue,
  ctaLabel = "Read more",
  videoUrl,
}: CardDataType) {
  return (
    <div className={clsx(styles.cardWrapper, className)}>
      <HeaderWrapper url={url}>
        <div className={styles.imageWrapper}>
          <ClipPath version={2} className={styles.clipPath}>
            <>
              <div className={styles.labels}>
                {Array.isArray(labels) &&
                  labels.map((label) => (
                    <Tag
                      type={label}
                      key={label}
                      size="small"
                      className={styles.verticalCardTag}
                    />
                  ))}
              </div>
              {videoUrl && <InlineVideo video_url={videoUrl} />}
              {heroImage?.image && (
                <Image
                  data={heroImage}
                  fluid
                  options={{ width: 540, height: 240 }}
                />
              )}
              {!videoUrl && !heroImage?.image && <ImagePlaceholder />}
            </>
          </ClipPath>
        </div>
        {publishedDate && (
          <span className={styles.date}>
            {dayjs(publishedDate).format("DD MMMM YYYY")}
          </span>
        )}
        {badge && <span className={styles.badge}>{badge}</span>}
        <div className={styles.content}>
          <Heading level={3}>{title}</Heading>
          {excerpt && <p>{`${truncate(excerpt, 28)}`}</p>}
        </div>
      </HeaderWrapper>
      {categories && categories.length > 0 && (
        <div className={styles.categories}>
          {categories?.map((category) => (
            <Link to={`/scientific-updates/${category}`} key={category}>
              <Tag
                text={getNameByValue && getNameByValue(category)}
                size="small"
              />
            </Link>
          ))}
        </div>
      )}
      <LinkComponent
        type="external"
        url={videoUrl || url}
        with_icon
        className={styles.readMore}
      >
        {ctaLabel}
      </LinkComponent>
    </div>
  );
}
