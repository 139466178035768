// extracted by mini-css-extract-plugin
export var badge = "styles-module--badge--4cdde";
export var cardWrapper = "styles-module--card-wrapper--0fe2b";
export var categories = "styles-module--categories--253a7";
export var clipPath = "styles-module--clip-path--38ac4";
export var content = "styles-module--content--a8a81";
export var date = "styles-module--date--4543f";
export var imageWrapper = "styles-module--image-wrapper--b3159";
export var labels = "styles-module--labels--0322f";
export var link = "styles-module--link--4f44b";
export var readMore = "styles-module--read-more--bbd50";
export var verticalCardTag = "styles-module--vertical-card-tag--8cd4c";
export var videoContainer = "styles-module--video-container--71959";