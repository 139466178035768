import useDataSource from "@/base/hooks/useDataSource";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import VerticalCard from "@/components/elements/VerticalCard";

import * as listStyles from "@/components/sections/ScientificUpdateList/styles.module.scss";
import * as styles from "./styles.module.scss";

interface ScientificUpdateCategoryListProps {
  list?: (ScientificUpdatePageTemplate & { full_slug: string })[];
  category: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  totalAmount: React.MutableRefObject<number>;
  amountPerPage: number;
  tag: string;
  heading?: string;
  subheading?: string;
}

function ScientificUpdateCategoryList({
  list = [],
  category,
  setPage,
  page,
  totalAmount,
  amountPerPage,
  heading,
  subheading,
  tag,
}: ScientificUpdateCategoryListProps) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const totalPages = Math.ceil(totalAmount.current / amountPerPage);
  const itemsExists = page < totalPages;

  const formatCategory = category.includes("Books")
    ? "Reports & books"
    : category;

  const headingContent = heading ? (
    <>
      <Heading level={1}>{heading}</Heading>
      <p>{subheading}</p>
    </>
  ) : (
    <>
      <LinkComponent type="internal" url={`/${tag}`}>
        {tag} /
      </LinkComponent>
      <Heading level={1}>Tag: “{formatCategory}”</Heading>
    </>
  );

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.headingContent}>{headingContent}</div>
        <div className={listStyles.updatesList}>
          {list.map((scientificUpdate) => (
            <VerticalCard
              key={scientificUpdate._uid}
              title={scientificUpdate.su_title}
              publishedDate={scientificUpdate.su_date_of_publication}
              heroImage={scientificUpdate.su_hero_img}
              excerpt={scientificUpdate.su_excerpt}
              labels={scientificUpdate.su_labels}
              categories={scientificUpdate.su_categories}
              url={scientificUpdate.full_slug}
              getNameByValue={getNameByValue}
            />
          ))}
        </div>
      </div>

      {itemsExists && (
        <Button
          variant="primary"
          onClick={() => setPage((prevPage) => prevPage + 1)}
          className={styles.buttonGetMore}
        >
          Get more
        </Button>
      )}

      <img src="/img/grey-bg.png" alt="" className={styles.greyBg} />
    </section>
  );
}

export default ScientificUpdateCategoryList;
