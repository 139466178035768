import { useEffect, useState } from "react";
import { Storyblok } from "@/base/lib/storyblok";

export default function useDataSource<
  T extends LabelType | CategoryType | ResourcesDataType
>(datasource: DataSourceSlug) {
  const [entries, setEntries] = useState<DataSourceEntry<T>[]>([]);

  const fetchDataSource = async () => {
    const res = await Storyblok.get("cdn/datasource_entries/", {
      datasource,
    });

    setEntries(
      res.data.datasource_entries.map((i: DataSourceEntry<T>) => ({
        name: i.name,
        value: i.value,
      }))
    );
  };

  useEffect(() => {
    fetchDataSource();
  }, []);

  const getNameByValue = (value: T) => {
    const targetedElement = entries.find((item) => item.value === value);
    return targetedElement ? targetedElement.name : "";
  };

  return {
    dataSource: entries,
    getNameByValue,
  };
}
