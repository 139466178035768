import { graphql, PageProps } from "gatsby";
import { lazy, useEffect, useRef, useState } from "react";
import { StoryblokResult } from "storyblok-js-client";

import { useInView } from "react-intersection-observer";
import { Storyblok } from "@/base/lib/storyblok";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import MainLayout from "@/components/layout/Main";
import ScientificUpdateCategoryList from "@/components/sections/ScientificUpdateCategoryList";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";

import * as styles from "@/components/sections/ScientificUpdateCategoryList/styles.module.scss";
import LazyIntersectionObserver from "../elements/LazyIntersectionObserver";

type PageData = {
  header: StoryblokStory;
  footer: StoryblokStory;
  poll: StoryblokStory;
};

type PageContext = {
  category: CategoryType;
  categoryName: string;
  categoryHeading?: string;
  categorySubheading?: string;
};

function ScientificUpdateCategory({
  pageContext,
  data,
}: PageProps<PageData, PageContext>) {
  const totalAmount = useRef(0);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [_, handleIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState<
    (ScientificUpdatePageTemplate & { full_slug: string })[]
  >([]);

  const { category, categoryName, categoryHeading, categorySubheading } =
    pageContext;
  const { footer, header, poll } = data;

  const amountPerPage = process.env.GATSBY_SU_OFFSET
    ? parseInt(process.env.GATSBY_SU_OFFSET, 10)
    : 9;

  async function getSuByCategory() {
    handleIsLoading(true);
    const {
      data,
      total,
    }: Omit<StoryblokResult, "data"> & {
      data: { stories: ParsedStoryblokStory<ScientificUpdatePageTemplate>[] };
    } = await Storyblok.get(`cdn/stories`, {
      version: "published",
      filter_query: {
        component: { in: "template_scientificUpdate" },
        su_categories: {
          exists: category,
        },
      },
      page,
      cv: Date.now(),
      per_page: amountPerPage,
      sort_by: "content.su_date_of_publication:desc",
    });

    if (Array.isArray(data.stories)) {
      const newList = data.stories.map((el) => ({
        full_slug: `/${el.full_slug}`,
        ...el.content,
      }));
      setList([...list, ...newList]);
    }

    if (total && totalAmount.current !== total) {
      totalAmount.current = total;
    }
    handleIsLoading(false);
  }

  useEffect(() => {
    getSuByCategory();
  }, [page]);

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;
  const { formId } = JSON.parse(poll.content) as PollTemplate;

  const seo = {
    title: categoryHeading
      ? categoryHeading
      : `Scientific Update - ${categoryName}`,
    description: categorySubheading
      ? categorySubheading
      : `Scientific Update - ${categoryName}`,
    url: `/scientific-update/${categoryName}`,
  };

  const LazyNewsletter = lazy(() => import("@/components/sections/Newsletter"));

  return (
    <MainLayout
      header={parsedHeader}
      footer={parsedFooter}
      formId={formId}
      seo={seo}
      type="website"
    >
      {categoryHeading ? (
        <HeaderScientificUpdate
          className={styles.categoryWrapper}
          setPage={(page: number) => setPage(page + 1)}
          dataSourceCategory="categories"
          heading={categoryHeading}
          subheading={categorySubheading}
        />
      ) : (
        <HeaderScientificUpdate
          className={styles.categoryWrapper}
          setPage={(page: number) => setPage(page + 1)}
          dataSourceCategory="categories"
        >
          <div className={styles.headerTextContainer}>
            <LinkComponent type="internal" url="/scientific-updates/">
              Scientific Updates /
            </LinkComponent>
            <Heading level={1}>Tag: “{categoryName}”</Heading>
          </div>
        </HeaderScientificUpdate>
      )}
      <ScientificUpdateCategoryList
        list={list}
        category={categoryName}
        setPage={setPage}
        page={page}
        amountPerPage={amountPerPage}
        totalAmount={totalAmount}
        heading={categoryHeading}
        subheading={categorySubheading}
        tag="scientific-updates"
      />
      <LazyIntersectionObserver
        Component={LazyNewsletter}
        inView={inView}
        ref={ref}
      />
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query SuCategoryPageQuery {
    header: storyblokEntry(field_component: { eq: "template_header" }) {
      content
    }
    footer: storyblokEntry(field_component: { eq: "template_footer" }) {
      content
    }
    poll: storyblokEntry(field_component: { eq: "template_poll" }) {
      content
    }
  }
`;

export default ScientificUpdateCategory;
