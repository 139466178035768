import ReactPlayer from "react-player";
import {
  fathomTrack,
  fathomEventsAvailable,
  type FathomAnalyticsProps,
} from "@/base/utils/fathom-analytics";
import {
  GoogleTagManagerEvents,
  gtmEvent,
  type GoogleTagManagerProps,
} from "@/base/utils/google-tag-manager";

const handleClick = (videoUrl: string) => {
  const event: FathomAnalyticsProps = {
    eventName: fathomEventsAvailable["embedded-video-button-play"],
    props: {
      location: window.location.pathname,
      videoUrl,
    },
  };
  const googleEvent: GoogleTagManagerProps = {
    event: GoogleTagManagerEvents.EmbeddedVideoButtonPlay,
    props: {
      videoUrl,
    },
  };

  fathomTrack(event);
  gtmEvent(googleEvent);
};

export function InlineVideo({ video_url }: Partial<VideoComponent>) {
  if (!video_url) {
    return null;
  }

  return (
    <div>
      <ReactPlayer
        url={video_url}
        controls
        width="100%"
        height="240px"
        playsinline
        onStart={() => handleClick(video_url)}
      />
    </div>
  );
}
