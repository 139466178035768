import { Ref, Suspense, forwardRef } from "react";

interface LazyIntersectionObserverProps {
  Component: React.ComponentType<any>;
  inView: boolean;
}

const LazyIntersectionObserver = (
  { Component, inView, ...otherProps }: LazyIntersectionObserverProps,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <div ref={ref}>
      {inView ? (
        <Suspense fallback={<div> Loading ...</div>}>
          <Component {...otherProps} />
        </Suspense>
      ) : null}
    </div>
  );
};

export default forwardRef<HTMLDivElement, LazyIntersectionObserverProps>(
  LazyIntersectionObserver
);
